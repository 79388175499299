<template>
  <div class="home-view" :class="{ 'is-full-screen': isFullScreen }">
    <div class="map-wrapper">
      <div id="map"></div>
    </div>
    <div class="top" style="flex: 5">
      <div class="top-left flex-level">
        <img src="../../../../assets/img/time.png" />
        <span class="time">{{ date }}</span>
        <span class="time small">{{ day }}</span>
        <span class="time">{{ time }}</span>
      </div>
      <div class="top-middle">{{ commonData.siteName }}</div>
      <div class="top-right flex-level">
        <div class="version">{{ commonData.siteAddress }}</div>
        <el-tooltip effect="light" content="全屏" placement="left">
          <img
            class="fullscreen"
            src="../../../../assets/img/fullscreen.png"
            @click="fullscreen"
          />
        </el-tooltip>
      </div>
    </div>
    <div class="left">
      <div class="panel flexable">
        <div class="panel-title">方量数据</div>
        <div class="panel-content" style="min-height: 120px">
          <div class="flex-level" style="margin-top: 10px">
            <div class="container1">
              <div>已完成方量</div>
              <div class="small">
                <span class="big">
                  <count-to
                    :start-val="0"
                    :end-val="commonData.finishedQuantity"
                    :duration="2000"
                  />
                </span>
              </div>
            </div>
            <div class="container1">
              <div>今日完成方量</div>
              <div class="small">
                <span class="big">
                  <count-to
                    :start-val="0"
                    :end-val="commonData.todayQuantity"
                    :duration="2000"
                  />
                </span>
              </div>
            </div>
            <div class="container1">
              <div>累计工作天数</div>
              <div class="small">
                <span class="big">
                  <count-to
                    :start-val="0"
                    :end-val="commonData.workDays"
                    :duration="2000"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel flexable" style="flex: 5">
        <div class="panel-title">今日车辆数据</div>
        <div class="panel-content" style="height: 300px; flex: 5">
          <div class="flex-level">
            <div class="container1">
              <div>车辆总数</div>
              <div class="small">
                <span class="big">
                  <count-to
                    :start-val="0"
                    :end-val="this.carInfo.totalNumber"
                    :duration="2000"
                  />
                </span>
              </div>
            </div>
            <div class="container1">
              <div>施工车辆数</div>
              <div class="small">
                <span class="big">
                  <count-to
                    :start-val="0"
                    :end-val="this.carInfo.workingNumber"
                    :duration="2000"
                  />
                </span>
              </div>
            </div>
            <div class="container1">
              <div>到达车辆数</div>
              <div class="small">
                <span class="big">
                  <count-to
                    :start-val="0"
                    :end-val="this.carInfo.arrivedNumber"
                    :duration="2000"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="carInfoList" style="flex: 5">
            <div class="carItem" v-for="(item, index) in carList" :key="index">
              <div v-if="item.carStatus == '施工中'">
                <img src="/img/state-dark-2.png" alt="" />{{ item.number }}
              </div>
              <div v-else-if="item.carStatus == '已到达'">
                <img src="/img/state-dark-1.png" alt="" />{{ item.number }}
              </div>
              <div v-else>
                <img src="/img/state-dark-5.png" alt="" />{{ item.number }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel flexable" style="flex: 5">
        <div class="panel-title">今日任务</div>
        <div class="panel-content">
          <div style="height: 360px; flex: 5">
            <div class="taskList">
              <div class="taskColumn1">时间</div>
              <div class="taskColumn1">施工部位</div>
              <div class="taskColumn2">车辆</div>
              <div class="taskColumn3">状态</div>
              <div class="taskColumn4">预计方量</div>
            </div>
            <div class="taskList" v-for="(item, index) of carList" :key="index">
              <div class="taskColumn1">{{ item.dispatchTime }}</div>
              <div class="taskColumn1">{{ item.constructionPart }}</div>
              <div class="taskColumn2">
                {{ item.number == null ? "待分配" : item.number }}
              </div>
              <div class="taskColumn3">{{ item.carStatus }}</div>
              <div class="taskColumn4">{{ item.quantityNumber || 0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <!-- <img src="/img/map-corner-6s.gif" alt="" style="width: 100px; height: 100px; position: absolute; right: -14px; top: -70px;"> -->
      <!-- <div style="width: 150px; height: 150px; position: absolute; right: -14px; top: -120px;" id="home-svg1"></div> -->
      <div class="panel flexable">
        <div class="panel-title flex-level">
          <div class="bg-opacity">
            <div>实时视频监控</div>
            <div class="search-row flex-level" style="width: auto">
              <el-select
                size="mini"
                ref="select"
                clearable
                filterable
                v-model="currentCar"
              >
                <el-option
                  v-for="s in carList"
                  :key="s.carId"
                  :label="s.number"
                  :value="s.carDeviceId"
                ></el-option>
              </el-select>
              <div
                class="link-btn toggle-videos-btn"
                :class="{ disabled: videosLoading }"
                @click="toggleVideos()"
              >
                <i class="el-icon-loading" v-show="videosLoading"></i>
                {{ toggleVideosFlag ? "关闭视频" : "开启视频" }}
              </div>
              <!-- <div class="link-btn" @click="$router.push({ name: 'production-monitor' })">后台管理</div> -->
            </div>
          </div>
        </div>
        <div class="panel-content">
          <div class="video-list">
            <el-row :gutter="2" v-if="videoList.length === 6">
              <el-col :span="8" v-for="(v, i) in videoList" :key="i">
                <flv-player2 :video-url="v.videoUrl"></flv-player2>
              </el-col>
            </el-row>
            <el-row :gutter="2" v-if="videoList.length === 0">
              <el-col :span="8" v-for="(v, i) in 6" :key="i">
                <img
                  src="/img/videoDefault.png"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="panel flexable" style="flex: 5">
        <div class="panel-title">近7日方量</div>
        <div class="panel-content" style="height: 240px">
          <div id="lineChart1" style="height: 100%; flex: 5"></div>
        </div>
      </div>
      <div class="panel flexable" style="flex: 5">
        <div class="panel-title">今日分时方量</div>
        <div class="panel-content" style="height: 240px">
          <div id="lineChart2" style="height: 100%; flex: 5"></div>
        </div>
      </div>
      <div class="panel flexable" style="flex: 5">
        <div class="panel-title">今日实时方量</div>
        <div class="panel-content" style="height: 245px; flex: 5">
          <div class="taskList">
            <div class="taskColumn6">车辆</div>
            <div class="taskColumn6">方量</div>
            <div class="taskColumn5">时间</div>
          </div>
          <div class="taskList" v-for="(item, index) of todayQuantityList" :key="index">
            <div class="taskColumn6">{{ item.number }}</div>
            <div class="taskColumn6">{{ item.quantityNumber || 0 }}</div>
            <div class="taskColumn5">{{ item.reportTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountTo from 'vue-count-to'
import screenfull from 'screenfull'
// import { mapCenter, restfulUrl, webSocketUrl } from '@/config'
import { restfulUrl, webSocketUrl } from '@/config'
import FlvPlayer2 from '@/components/flv-player2.vue'
// import { createLineEchart, pieOption, gridBarOption } from '../../../../utils/echartUtils.js'
import dayjs from 'dayjs'
// import _ from 'lodash'
import axios from 'axios'
import echarts from 'echarts'

export default {
  name: 'siteuser_index',

  components: { FlvPlayer2, CountTo },
  data () {
    return {
      lineChart1: null,
      lineChart2: null,
      date: '',
      day: '',
      time: '',
      currentCar: '',
      videoList: [],
      commonData: {}, // 工地信息，方量数据
      isFullScreen: false, // 当前是否是全屏
      videosLoading: false,
      toggleVideosFlag: false, // 是否开启视频监控
      carList: [], // 车辆信息
      carInfo: {},
      todayQuantityList: [], // 今日实时方量数据
      latestQuantityList: [], // 近7天方量数据
      hoursQuantityList: [] // 今天分时方量数据
    }
  },
  watch: {
    // currentCar (v) {
    //   if (v) {
    //     this.loadVideos()
    //   }
    // },

    expanded () {
      setTimeout(() => {
        this.handleWindowResize()
      }, 300)
    }
  },
  mounted () {
    this.map = new window.AMap.Map('map', {
      // center: mapCenter,
      zoom: 11,
      mapStyle: 'amap://styles/4dd53460c4d5e2a3d71246255cfd9ed7'
    })
    window.addEventListener('resize', this.handleWindowResize)
    this.startMapCenter = this.map.getCenter()
    this.startMapZoom = this.map.getZoom()
    this.$nextTick(() => {
      this.refreshTimer = setInterval(() => {
        this.updateData()
      }, 1000 * 30)
      //   this.svg2()
      this.updateData()
      //   this.createLine1()
      //   this.updateAlertList()
    })
  },
  async created () {
    const dayInWeek = [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六'
    ]
    this.$store.commit('common/toggleExpanded', false)
    const today = dayjs()
    this.date = today.format('YYYY/MM/DD')
    this.day = dayInWeek[today.day()]
    this.timer = setInterval(() => {
      this.time = dayjs().format('HH:mm:ss')
    }, 1000)
  },

  beforeDestroy () {
    console.log('home.vue beforeDestroy ...')
    this.destroyCharts()
    this.timer && clearInterval(this.timer)
    this.refreshTimer && clearInterval(this.refreshTimer)
  },

  methods: {
    destroyCharts () {
      this.lineChart1 && this.destroyChart(this.lineChart1)
      this.lineChart2 && this.destroyChart(this.lineChart2)
    },

    destroyChart (e) {
      window.removeEventListener('resize', e._dom.$resizer)
      e.clear()
      e.dispose()
    },
    /** 开启/关闭视频 */
    toggleVideos () {
      console.log(this.videosLoading)
      if (this.videosLoading) {
        return
      }
      if (!this.toggleVideosFlag) {
        this.loadVideos()
        this.toggleVideosFlag = true
      } else {
        this.videoList = []
        this.toggleVideosFlag = false
      }
    },
    /** 加载视频 */
    loadVideos () {
      if (!this.currentCar) {
        return
      }
      this.videoList = []
      const list = []
      let promiseArr = []
      for (let i = 3; i < 9; i++) {
        const param = {
          tdh: i,
          type: 1,
          tid: this.currentCar
        }
        list.push({
          // switchUrl: restfulUrl + `/api.json?func=1003&carid=${this.currentCar}&tdh=${i}`,
          videoUrl:
            webSocketUrl +
            `/websocket?token=&params=${encodeURIComponent(JSON.stringify(param))}`
        })
        promiseArr.push(
          new Promise((resolve, reject) => {
            axios
              .get(
                restfulUrl + `/api.json?func=1003&carid=${this.currentCar}&tdh=${i}&ml=0`
              )
              .then((data) => {
                if ([1, -85].includes(data.data.code)) {
                  resolve()
                } else {
                  reject(new Error('视频预连接不成功'))
                }
              })
              .catch((err) => {
                reject(err)
              })
          })
        )
      }
      this.videosLoading = true
      Promise.all(promiseArr)
        .then(() => {
          this.videoList = list
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'warning',
            customClass: 'el-message__home',
            message: '当前设备实时监控视频离线'
          })
        })
        .finally(() => {
          this.videosLoading = false
        })
    },
    handleWindowResize (e) {
      if (this.lineChart1) {
        this.lineChart1.resize()
      }
      if (this.lineChart2) {
        this.lineChart2.resize()
      }
    },

    // 全屏
    fullscreen () {
      let element = document.getElementsByClassName('home-view')[0]
      if (screenfull.isEnabled) {
        screenfull.request(element)
        screenfull.onchange(this.handleWindowResize)
      }
    },

    updateData () {
      // 通用数据
      this.$http({
        url: this.$http.adornUrl('/siteuser/index/querycommondata'),
        method: 'post'
      }).then((data) => {
        this.commonData = data.datas
        // console.log(this.commonData)
      })

      // 车辆数据
      this.$http({
        url: this.$http.adornUrl('/siteuser/index/querycardata'),
        method: 'post'
      }).then((data) => {
        // console.log(data.datas)
        this.carList = data.datas.detailDTOList
        this.carInfo.arrivedNumber = data.datas.arrivedNumber
        this.carInfo.workingNumber = data.datas.workingNumber
        this.carInfo.totalNumber = data.datas.totalNumber

        const AMap = window.AMap
        this.markList = []
        for (const item of this.carList) {
          let content = `<img width="30" src="/img/state-dark-5.png">`
          if (item.carStatus === '施工中') {
            content = `<img width="80" height="100" src="/img/state-dark-2.webp">`
            // content = `<div style="width: 100px; height: 100px;" class="car-animation-svg" ></div>`
          } else if (item.carStatus === '已到达') {
            content = `<img width="80" height="100" src="/img/state-dark-1.png">`
            // content = `<div style="width: 100px; height: 100px;" class="car-animation-svg" ></div>`
          }

          if (!item.lng || !item.lat) continue
          const marker = new AMap.Marker({
            position: new AMap.LngLat(item.lng, item.lat),
            content,
            anchor: 'bottom',
            title: item.number,
            item
          })
          let topValue = 0
          if (item.jobStatus === 2) {
            topValue = 45
          }
          let labelContent = '<div>&nbsp;' + item.number + '&nbsp;</div>'
          if (item.baseId === 39) {
            labelContent =
              '<div><span style="background-color:yellow">&nbsp;黄&nbsp;</span>&nbsp;' +
              item.number +
              '&nbsp;</div>'
          }
          if (item.baseId === 40) {
            labelContent =
              '<div><span style="background-color:green">&nbsp;水&nbsp;</span>&nbsp;' +
              item.number +
              '&nbsp;</div>'
          }
          if (item.baseId === 42) {
            labelContent =
              '<div><span style="background-color:red">&nbsp;白&nbsp;</span>&nbsp;' +
              item.number +
              '&nbsp;</div>'
          }
          marker.setLabel({
            offset: new AMap.Pixel(0, topValue), // 设置文本标注偏移量
            content: labelContent, // 设置文本标注内容
            direction: 'top' // 设置文本标注方位
          })
          this.markList.push(marker)
          this.map.add(marker)
        }
      })

      // 当天实时方量数据
      this.$http({
        url: this.$http.adornUrl('/siteuser/index/queryrealquantity'),
        method: 'post'
      }).then((data) => {
        this.todayQuantityList = data.datas
      })

      // 近7日方量数据
      this.$http({
        url: this.$http.adornUrl('/siteuser/index/querylatestdaysquantity'),
        method: 'post'
      }).then((data) => {
        // console.log(data.datas)
        // this.latestQuantityList = data.datas
        this.drawLine1Chart(data.datas)
        // console.log(this.latestQuantityList)
      })

      // 当天分时方量数据
      this.$http({
        url: this.$http.adornUrl('/siteuser/index/querytodayquantity'),
        method: 'post'
      }).then((data) => {
        // console.log(data.datas)
        // this.hoursQuantityList = data.datas
        this.drawLine2Chart(data.datas)
      })
    },

    drawLine1Chart (serialData) {
      //   console.log(serialData.data)
      this.lineChart1 = echarts.init(document.getElementById('lineChart1'))
      let option = {
        animation: false,
        // title: {
        //   text: '近7天借还趋势图',
        //   x: 'left',
        //   y: 'top',
        //   textAlign: 'left',
        //   padding: [20, 0, 10, 10],
        //   textStyle: {
        //     fontFamily: 'SourceHanSansCN-Regular',
        //     fontSize: 20,
        //     fontStyle: 'normal',
        //     fontWeight: 'normal',
        //     color: '#0febff'
        //   }
        // },
        legend: {
          icon: 'line',
          data: ['方量值'],
          x: '50%',
          padding: [20, 0, 10, 100],
          textStyle: {
            color: '#ffffff'
          }
        },
        color: ['#fed130', '#fc5659'],
        grid: {
          top: '20%',
          left: '2%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            // boundaryGap: false,  //表示第一个数据点与Y轴的距离，false表示无
            data: serialData.label,
            axisLine: {
              lineStyle: {
                color: '#42A4FF',
                width: 1 // 这里是为了突出显示加上的
              }
            },
            // 隐藏刻度线
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#fff' // 坐标值的具体的颜色
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#42A4FF'],
                width: 1,
                type: 'solid'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#42A4FF',
                width: 1 // 这里是为了突出显示加上的
              }
            },
            // 隐藏刻度线
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#fff' // 坐标值的具体的颜色
              }
            }
          }
        ],
        series: [
          {
            name: '方量值',
            type: 'line',
            itemStyle: {
              normal: {
                // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                areaStyle: {
                  type: 'default',
                  // 渐变色实现
                  color: echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1, // 变化度
                    // 三种由深及浅的颜色
                    [
                      {
                        offset: 0,
                        color: '#fed130'
                      },
                      {
                        offset: 1,
                        color: '#fceec3'
                      }
                    ]
                  )
                },
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: 'solid',
                  color: '#fed130'
                },
                // 以及在折线图每个日期点顶端显示数字
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#fed130'
                  }
                }
              }
            }, // 线条样式
            symbolSize: 10, // 折线点的大小
            areaStyle: {
              normal: {}
            },
            data: serialData.data
          }
        ]
      }
      this.lineChart1.setOption(option)
    },

    drawLine2Chart (serialData) {
      //   console.log(serialData.data)
      this.lineChart2 = echarts.init(document.getElementById('lineChart2'))
      let option = {
        animation: false,
        legend: {
          icon: 'line',
          data: ['方量值'],
          x: '50%',
          padding: [15, 0, 0, 10],
          textStyle: {
            color: '#ffffff'
          }
        },
        color: ['#0AC1EB', '#D5B346'],
        grid: {
          top: '20%',
          left: '2%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            // boundaryGap: false,  //表示第一个数据点与Y轴的距离，false表示无
            data: serialData.label,
            axisLine: {
              lineStyle: {
                color: '#42A4FF',
                width: 1 // 这里是为了突出显示加上的
              }
            },
            // 隐藏刻度线
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#fff' // 坐标值的具体的颜色
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#42A4FF'],
                width: 1,
                type: 'solid'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#42A4FF',
                width: 1 // 这里是为了突出显示加上的
              }
            },
            // 隐藏刻度线
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#fff' // 坐标值的具体的颜色
              }
            }
          }
        ],
        series: [
          {
            name: '借出',
            type: 'line',
            itemStyle: {
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: 'solid',
                  color: '#0AC1EB'
                },
                // 以及在折线图每个日期点顶端显示数字
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#0AC1EB'
                  }
                }
              }
            }, // 线条样式
            symbolSize: 10, // 折线点的大小
            data: serialData.data
          }
        ]
      }
      this.lineChart2.setOption(option)
    }
  }
}
</script>
<style scoped lang="scss">
.home-view {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  min-width: 1366px;
  min-height: 937px;
  .map-wrapper {
    width: 100%;
    height: 100%;
    .map-info {
      position: absolute;
      top: 88px;
      left: 50%;
      z-index: 10;
      transform: translateX(-50%);
      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 2px;
        height: 14px;
        background: rgba(76, 182, 255, 1);
        top: 8px;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
      .info-bg {
        // background: linear-gradient(
        //   180deg,
        //   rgba(3, 15, 100, 0) 0%,
        //   rgba(8, 19, 104, 0.76) 100%
        // );
        background-color: rgba(8, 19, 104, 0.8);
        border: 1px solid rgba(19, 60, 176, 1);
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
      }
      .info-content {
        display: flex;
        padding: 7px 15px;
        .info-area {
          font-size: 14px;
          margin-right: 20px;
          color: #fff;
          display: flex;
          img {
            height: 16px;
            margin-right: 5px;
          }
        }
      }
    }
    #map {
      height: 100%;
    }
  }
  .top {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 26.5%;
    > img {
      position: relative;
      top: 12px;
      width: 100%;
    }
    .top-left {
      position: absolute;
      top: 4px;
      left: 20px;
      width: 24%;
      padding: 6px 8px;
      color: #fff;
      font-size: 20px;
      line-height: 30px;
      // background: linear-gradient(
      //   180deg,
      //   rgba(3, 15, 100, 0) 0%,
      //   rgba(8, 19, 104, 0.69) 100%
      // );
      background-color: rgba(8, 19, 104, 0.8);
      box-shadow: 0px 0px 4px 0px rgba(0, 80, 243, 0.7);
      border-radius: 4px;
      img {
        width: 30px;
        margin-right: 20px;
      }
      .small {
        font-size: 16px;
      }
      .time {
        margin-right: 20px;
      }
    }
    .top-middle {
      position: absolute;
      top: 4px;
      width: 46%;
      padding: 6px 8px;
      color: #fff;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      // background: linear-gradient(
      //   180deg,
      //   rgba(3, 15, 100, 0) 0%,
      //   rgba(8, 19, 104, 0.69) 100%
      // );
      background-color: rgba(8, 19, 104, 0.8);
      box-shadow: 0px 0px 4px 0px rgba(0, 80, 243, 0.7);
      border-radius: 4px;
    }
    .top-right {
      position: absolute;
      top: 10px;
      right: 20px;
      color: #fff;
      font-size: 18px;
      width: auto;
      img {
        height: 30px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .no-data {
    height: 75px;
    width: 112px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .panel {
    &.flexable {
      display: flex;
      flex-direction: column;
      .panel-content {
        flex: 1;
      }
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .panel-title {
      font-size: 18px;
      color: #fff;
      line-height: 30px;
      margin-bottom: 5px;
      background-color: rgba(8, 19, 104, 0.8);
      .el-radio-group {
        .el-radio-button__inner {
          padding: 3px 9px;
          background-color: transparent;
          border-color: #3b7cff;
          color: #3b7cff;
        }
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          background-color: #3b7cff;
          border-color: #3b7cff;
          color: #fff;
        }
      }
    }
    .panel-content {
      background: rgba(2, 32, 140, 1);
      // border-radius: 4px;
      border-top: 1px solid #0050f3;
      color: #fff;
      font-size: 14px;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 25px;
        height: 4px;
        background: rgba(76, 182, 255, 1);
        top: -2px;
        z-index: 10;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
      .container1 {
        width: 50%;
        padding: 20px 20px 10px;
        text-align: center;
        .small {
          font-size: 12px;
          margin-top: 5px;
        }
        .big {
          font-size: 28px;
          font-weight: bold;
          color: #ffb400;
          margin-right: 5px;
        }
      }
      .container2 {
        width: 33.33%;
        text-align: center;
        &:not(:last-child) {
          border-right: 1px dashed #133caf;
        }
        .label {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.37);
          line-height: 24px;
        }
        .text {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
        }
      }
      .c3-wrapper {
        background: linear-gradient(
          180deg,
          rgba(3, 15, 100, 0) 0%,
          rgba(8, 19, 104, 0.69) 100%
        );
        border: 1px solid rgba(19, 60, 176, 1);
        margin-bottom: 10px;
      }
      .container3 {
        margin: 7px 0 2px;
        width: 50%;
        text-align: center;
        font-size: 24px;
        color: #cd9715;
        position: relative;
        &:first-child {
          border-right: 1px solid #133cb0;
        }
        &::before {
          position: absolute;
          content: "";
          width: 16px;
          height: 2px;
          background: #4cb6ff;
          top: -7px;
          left: 50%;
          transform: translateX(-50%);
        }
        .label {
          font-size: 12px;
          color: #fff;
          line-height: 20px;
        }
      }
      .c4-wrapper {
        background: linear-gradient(
          180deg,
          rgba(3, 15, 100, 0) 0%,
          rgba(8, 19, 104, 0.69) 100%
        );
        border: 1px solid rgba(19, 60, 176, 1);
        margin-bottom: 10px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          height: 13px;
          width: 2px;
          background: #4cb6ff;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .container4 {
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        color: #cd9715;
        margin: 8px 0;
        border-right: 1px dashed #133caf;
        flex: 0 0 140px;
        .big {
          color: #fff;
        }
        .small {
          color: #fff;
          font-size: 14px;
        }
      }
      .container5 {
        width: 100%;
        height: 100%;
        .el-row {
          height: 100%;
          .el-col {
            color: #67e1e4;
            font-size: 24px;
            text-align: center;
            padding: 5px 0;
            .mini {
              font-size: 12px;
              color: #fff;
            }
            .small {
              font-size: 14px;
              color: #fff;
            }
            .flex-level {
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .left {
    &.invisible {
      left: calc(-24% - 20px);
      //   bottom: -45%;
    }
    transition: left 0.2s;
    position: absolute;
    top: 56px;
    left: 20px;
    bottom: 15px;
    width: 26%;
    display: flex;
    flex-direction: column;
  }
  .middle {
    &.invisible {
      bottom: -45%;
    }
    .panel {
      height: 100%;
    }
    .panel-content {
      height: calc(100% - 45px);
    }
    transition: bottom 0.2s;
    position: absolute;
    bottom: 15px;
    left: calc(26% + 40px);
    right: calc(24% + 40px);
    height: 40vh;
    .el-select {
      margin: 0 20px;
      .el-input {
        line-height: 24px;
        input {
          height: 24px;
          line-height: 24px;
          background: transparent;
          border-radius: 12px;
          border-color: #3b7cff;
          color: #3b7cff;
          &::-webkit-input-placeholder {
            color: #3b7cff;
          }
        }
        .el-select__caret {
          color: #3b7cff;
        }
      }
    }
    .link-btn {
      font-size: 14px;
      line-height: 24px;
      padding: 0 10px;
      color: #fff;
      background: rgba(59, 124, 255, 1);
      border-radius: 4px;
      cursor: pointer;
    }
    .toggle-videos-btn {
      margin-right: 10px;
      i.el-icon-loading {
        // display: inline;
        margin-right: 4px;
        font-size: 14px;
        color: #fff;
      }
      &.disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
    .video-list {
      background: #02208c;
      height: 100%;
      padding: 10px;
      .el-row {
        height: 100%;
      }
      .el-col {
        margin-bottom: 2px;
        height: 50%;
      }
    }
  }
  .right {
    &.invisible {
      right: calc(-24% - 20px);
    }
    transition: right 0.2s;
    position: absolute;
    right: 20px;
    top: 64px;
    bottom: 15px;
    width: 24%;
    display: flex;
    flex-direction: column;
    .flex-spread-between {
      .label {
        font-size: 18px;
        color: #fff;
      }
      .text {
        // border:1px solid red;
        font-size: 18px;
        color: #ffdb5c;
        .unit {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .alert-row {
      border-bottom: 1px dashed #123cb1;
      padding: 10px 0;
      .alert-tag {
        line-height: 21px;
        font-size: 12px;
        color: #fff;
        padding: 0 10px;
        border-radius: 12px;
        margin-right: 10px;
        flex: 0 0 70px;
        &.info {
          background: #139ad5;
        }
        &.alert {
          background: #d19f19;
        }
        &.danger {
          background: #da1f1f;
        }
      }
      .text {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        overflow: hidden;
        word-wrap: break-word;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      .time {
        margin-left: auto;
        font-size: 12px;
        color: #7590f9;
        flex: 0 0 auto;
        text-align: right;
      }
    }
  }
  #bar1,
  #line1,
  #pie1,
  #pie2 {
    width: 100%;
    height: 100%;
  }
  #bar1copy {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .hidden {
    visibility: hidden !important;
  }
  .chart-no-data {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    img {
      width: 168px;
      height: auto;
      display: block;
      margin: auto;
    }
  }
  .line1-wrapper,
  .bar1-wrapper {
    position: relative;
  }
  .chart-loading {
    .el-loading-spinner {
      i {
        color: #fff;
        font-size: 32px;
      }
      .el-loading-text {
        color: #fff;
        font-size: 20px;
      }
    }
  }
  .bg-opacity {
    background: rgba(8, 19, 104, 0.8);
    box-shadow: 0px 0px 4px 0px rgba(0, 80, 243, 0.7);
    display: flex;
    border-radius: 4px;
    padding: 5px 8px;
  }
  .pie-section {
    // background-color: blueviolet;
    height: 160px;
    display: flex;
    justify-content: space-around;
    padding: 5px 20px;
    .pie1-div {
      width: 160px;
    }
    .pie2-div {
      width: 220px;
    }
    .pie-div {
      position: relative;
      .pie-rotate-img {
        // width: 146px;
        height: 100%;
        display: block;
        position: absolute;
        top: 0px;
        left: -9px;
        animation: pieRotate 6s linear 0.2s infinite;
      }
      &.pie2-div {
        .pie-rotate-img {
          left: 2px;
        }
      }
    }
  }
  .ratio-bar-section {
    display: flex;
    // background-color: brown;
    height: 34px;
    padding: 8px 8px;
    .lt,
    .rt {
      line-height: 18px;
    }
    .bar-wrapper {
      flex: 1;
      background-color: #0283ff;
      position: relative;
      margin: 0 8px;
      border-radius: 12px;
      .left-bar {
        border-radius: 12px;
        height: 100%;
        position: absolute;
        left: 0;
        width: 0;
        transition: all 0.3s;
        background-color: #16ceb9;
      }
    }
  }
}

@keyframes pieRotate {
  0% {
    transform: rotate(1turn);
  }
  to {
    transform: rotate(0deg);
  }
}
body .el-message__home {
  background-color: rgba(#02208c, 0.7);
  border-color: rgba(#02208c, 0.9);
  .el-message__content {
    color: #fff;
  }
  .el-message__icon {
    color: #fff;
  }
}

.amap-marker-label {
  padding: 0;
}

.carInfoList {
  display: flex;
  flex-wrap: wrap;
}

.carItem {
  width: 25%;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
}

.taskList {
  display: flex;
  text-align: center;
  padding: 5px 0;
}

.taskColumn1 {
  width: 30%;
}
.taskColumn2 {
  width: 23%;
}
.taskColumn3 {
  width: 23%;
}
.taskColumn4 {
  width: 24%;
}
.taskColumn5 {
  width: 40%;
}
.taskColumn6 {
  width: 30%;
}
</style>
